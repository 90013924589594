import React from "react"
import Iconify from "./iconify.js"
import HTitle from "./h-title.js"

const Blurb = ({ children, icon, color, title }) => {
  if (!color) {
    color = "text-teal-700";
  }
  return (
    <>
      <div className="flex items-center xs:flex-col">
        <Iconify name={icon} className={"flex-none " + color + " text-7xl"} />
        <div className="ml-4 xs:ml-0 xs:text-center">
          {title && (
            <HTitle Type="h5" className="font-semibold">{title}</HTitle>
          )}
          <div>{children}</div>
        </div>
      </div>
    </>
  )
}

export default Blurb
