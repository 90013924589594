import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/main-layout.js"
import Row from "../components/row.js"
import HeaderImage from "../components/elements/header-image.js"
import HTitle from "../components/elements/h-title.js"
import BlockQuote from "../components/elements/block-quote.js"
import Blurb from "../components/elements/blurb.js"

const Page = ({ location, data }) => (
  <>
    <SEO title="About me" location={location} />
    <Layout location={location}>
      <Row xFull hFull noSeparated>
        <HeaderImage image={data.logo.childImageSharp.fluid}>
          <div className="flex w-full h-full items-center justify-center">
            <BlockQuote
              className="max-w-3/4 bg-white-tr-10 p-2"
              quote="You are who you are when nobody's watching."
              footer="Stephen Fry"
            />
          </div>
        </HeaderImage>
      </Row>

      <Row>
        <HTitle Type="h3">About me</HTitle>
        <div className="grid row-gap-4 col-gap-2 grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4">
          <Blurb icon="cartOutline">
            I am a senior full stack web developer with good knowledge of
            e-commerce.
          </Blurb>
          <Blurb icon="codeJson">
            I enjoy writing clean and structured code.
          </Blurb>
          <Blurb icon="speedometerIcon">
            I am thrilled to use performance and optimization techniques.
          </Blurb>
          <Blurb icon="trendingUp">
            I can easily adapt to new tools and technology trends.
          </Blurb>
        </div>
      </Row>

      <Row>
        <HTitle Type="h3">Details</HTitle>
        <div className="grid row-gap-4 col-gap-2 grid-cols-1 xs:grid-cols-2 sm:grid-cols-3">
          <Blurb icon="accountOutline" title="Name">
            Peter Agazanis
          </Blurb>
          <Blurb icon="babyCarriage" title="Age">
            38
          </Blurb>
          <Blurb icon="mapMarkerRadiusOutline" title="Location">
            Athens, Greece
          </Blurb>
        </div>
      </Row>

      <Row>
        <HTitle Type="h3">Hobbies & other activities</HTitle>
        <div className="grid row-gap-4 col-gap-2 grid-cols-1 xs:grid-cols-2 sm:grid-cols-3">
          <Blurb icon="tennisIcon" title="Tennis">
            Semi pro player – Best ranking 15th in Greek Tennis Leaderboard
          </Blurb>
          <Blurb icon="volleyballIcon" title="Volleyball">
            Former member and leader of a registered team
          </Blurb>
          <Blurb icon="ninjaIcon" title="Ninjutsu">3 years intensive training</Blurb>
        </div>
      </Row>
    </Layout>
  </>
)

export const query = graphql`
  {
    logo: file(relativePath: { eq: "abstract-2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Page
